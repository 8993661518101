<template>
    <div>
        <SeoStructuredDataContent
            v-if="formattedFaqData.length"
            component="SeoStructuredDataFaq"
            :data="formattedFaqData"
        />
        <SeoStructuredDataBrand v-if="products.length" :brand="brand" :products="products" :reviews="reviews" />
        <div id="page-brand" class="mt-2 mt-md-0">
            <div>
                <BrandHeader :brand="brand" :products="products" :reviews="reviews" />
                <BrandContent
                    :trustpilot-skus="productIds"
                    :products="products"
                    :brand="brand"
                    :faq="formattedFaqData"
                    :faq-title="faqTitle"
                    :alternative-brands="alternativeBrands"
                    :reviews="reviews"
                    :articles="articles"
                    :notifications="notifications"
                />
                <section v-if="relatedBrands.length > 1" class="related-brands">
                    <UIGridContainer>
                        <h2>{{ translate('giftcard.recommended') }}</h2>
                        <UIGridRow class="related-brand-wrapper">
                            <UIGridCol v-for="(relatedBrand, key) in relatedBrands" :key="key" cols="6" md="2">
                                <nuxt-link
                                    :to="$brand.getPath(relatedBrand)"
                                    class="related-brand"
                                    :title="$brand.getName(relatedBrand)"
                                >
                                    <ProductBrandImage size="md" :brand="relatedBrand" :lazy="true" class="mb-1" />
                                    <div class="name">
                                        {{ $brand.getName(relatedBrand) }}
                                    </div>
                                </nuxt-link>
                            </UIGridCol>
                        </UIGridRow>
                    </UIGridContainer>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { UIGridCol, UIGridRow, UIGridContainer } from '@dundle/ui/components';
import BreadcrumbMixin from '~/mixins/BreadcrumbMixin';
import TranslationMixin from '~/mixins/TranslationMixin';
import SeoStructuredDataContent from '~/components/seo/SeoStructuredDataContent';
import { BrandHeader, BrandContent } from '~/components/brand';
import ProductBrandImage from '~/components/product/ProductBrandImage';
import SecurityMixin from '~/mixins/SecurityMixin';
import SeoStructuredDataBrand from '~/components/seo/SeoStructuredDataBrand';

export default {
    name: 'BrandPage',
    components: {
        BrandHeader,
        BrandContent,
        UIGridCol,
        UIGridRow,
        UIGridContainer,
        SeoStructuredDataBrand,
        ProductBrandImage,
        SeoStructuredDataContent,
    },

    mixins: [TranslationMixin, BreadcrumbMixin, SecurityMixin],

    middleware: 'cache-tags',

    meta: {
        slash: true,
        footer: 'version-1',
    },

    async asyncData({ app, params, query, redirect, error, store }) {
        // query param 'c' type (country or currency) difference is based on length
        let currency = query.c && query.c.length === 3 ? query.c : app.$locale.currency;
        const country = query.c && query.c.length === 2 ? query.c : app.$locale.regio;

        if (params.brand === 'uber-eats') {
            return redirect(302, app.$locale.path({ name: 'brand', params: { brand: 'uber' } }));
        }

        let articles;
        try {
            // const language = store.getters['magazineArticles/getForLanguage'].includes(app.$locale.language)
            //     ? app.$locale.language
            //     : 'en';
            const language = ['nl', 'fr', 'pl', 'de'].includes(app.$locale.language) ? app.$locale.language : 'en';
            articles = await app.$api.article.getArticleByBrand(params.brand, language);
            if (!articles.length && language !== 'en') {
                const articleData = await app.$api.article.getLatestArticles(language);
                articles = articleData.data || [];
            }

            if (!articles.length) {
                articles = await app.$api.article.getArticleByBrand(params.brand, 'en');
            }
        } catch (error) {
            app.$sentry?.captureException(error);
        }

        const formattedFaqData = [];
        let faqTitle;

        try {
            const faqData = await app.$api.brand.getBrandFaq(params.brand);
            const faqs = JSON.parse(faqData.data);

            faqTitle = faqData.title;
            // eslint-disable-next-line prefer-const
            for (let [key, value] of Object.entries(faqs)) {
                // regex for internal linking they start with '/' in href
                // the positive lookbehind is not supported by old browsers thats why we need to capture the group
                const internalLinkRegex = /(<a href="\/)(.*?)(?=">)/gm;
                if (internalLinkRegex.test(value)) {
                    // add current region and language to the internal links
                    value = value.replace(internalLinkRegex, `$1${app.$locale.localizedIsoParams()}$2`);
                }

                formattedFaqData.push({
                    name: key,
                    answer: value,
                });
            }
        } catch (error) {
            app.$sentry?.captureException(error);
        }

        // flexepin USD product is only available for certain regions
        // we dont want to even mention it in other regions
        // this check is also in brandContent
        const flexepinBlockedRegions = [
            'ir', // Iran
            'kp', // North Korea
            'mm', // Myanmar
            'ru', // Russia
            'cn', // China
            'us', // United States of America
            'il', // Isreal
        ];
        const flexepinRule = params.brand === 'flexepin' && flexepinBlockedRegions.includes(app.$locale.region);
        // when flexepinRule and 'usd', get 'aud' products (1st alternative currency) instead of 'usd' products
        if (flexepinRule && (!query.c || query.c === 'usd')) currency = 'aud';

        let brand;
        try {
            brand = await app.$api.brand.getBySlug(params.brand, country, app.$locale.language, currency);

            // remove USD from the currency dropdown
            if (flexepinRule) brand.currencyCodes = brand.currencyCodes.filter(currency => currency !== 'usd');
        } catch (e) {
            console.error(e);
            error({
                statusCode: 404,
                message: 'Brand not found',
            });
            return;
        }

        let relatedBrands = [];
        try {
            relatedBrands = await app.$api.brand.getRecommendedBrands(brand.slug);
        } catch (error) {
            console.error(error);
        }

        // Filter brands that aren't active in region if target locale
        if (app.$locale.currentLocale.target) {
            relatedBrands = relatedBrands.filter(brand =>
                brand.availableInRegions.includes(app.$locale.regio.toLowerCase())
            );
        }

        // Filter out duplicate brands
        relatedBrands = [...new Map(relatedBrands.map(brand => [brand.id, brand])).values()];

        if (!brand.isActive) {
            error({
                statusCode: 404,
                message: 'Brand is inactive',
            });
            return;
        }

        const arrayProductIds = await app.$api.brand.getProductIds(params.brand);

        const joinedProductIds = [brand.slug, ...arrayProductIds].slice(0, 49).join(',');

        let reviews, productReviews;
        try {
            reviews = await app.$api.trustpilot.getProductReviewSummary(joinedProductIds);
            productReviews = await app.$api.trustpilot.getProductReviews(joinedProductIds, app.$locale.language, 1);
        } catch (error) {
            app.$sentry.captureException(error);
        }

        let products = [...brand.products, ...brand.children.map(childBrand => childBrand.products).flat()];

        if (products.some(p => p.type === 'currency') && products.some(p => p.type === 'region')) {
            const regionProducts = products.filter(p => p.type === 'region');
            const currencyProducts = products.filter(p => p.type === 'currency');

            products = [...regionProducts, ...currencyProducts];
        }

        // logic to show the ProductAlteternativeBrandProduct.vue component. moving it to this place will prevent the layout shifting
        const productsInStock = products.some(product => product.stock !== 0);
        const paysafecardRule =
            brand.slug === 'paysafecard' && !brand.regionCodes.includes(app.$locale.regio.toLowerCase());
        const neosurfRule = ['cy'].includes(app.$locale.region) && brand.slug === 'neosurf';
        const bitnovoRule = ['nl'].includes(app.$locale.region) && brand.slug === 'bitnovo';
        const showAlternativeBrands = !productsInStock || paysafecardRule || neosurfRule || bitnovoRule;
        // all of the movedProducts with their new url
        const movedProducts = {
            rixty: {
                newBrandSlug: 'razer-gold',
            },
        };
        const isMovedProduct = Object.keys(movedProducts).includes(brand.slug);

        let alternativeBrands;

        if (showAlternativeBrands || isMovedProduct) {
            if (isMovedProduct) {
                alternativeBrands = {
                    type: 'movedProduct',
                    brand: await app.$api.brand.getBySlug(movedProducts[brand.slug].newBrandSlug),
                };
            } else {
                const slugs = (await app.$api.brand.getPopularPaginated(brand.category)).data.map(brand => brand.slug);

                const brands = await Promise.all(
                    slugs.map(async brand => {
                        return await app.$api.brand.getBySlug(brand);
                    })
                );

                const filteredAlternativeBrands = brands
                    .filter(b => b.name !== brand.name)
                    .filter(b => b.products.some(product => product.stock !== 0));
                alternativeBrands = {
                    type: 'alternativeBrands',
                    brands: filteredAlternativeBrands,
                };
            }
        }
        const notifications = await app.$api.notifications.getNotifications();

        return {
            faqTitle,
            formattedFaqData,
            alternativeBrands,
            articles,
            brand,
            products,
            productsInStock,
            relatedBrands,
            productIds: joinedProductIds,
            notifications,
            reviews: {
                count: reviews?.numberOfReviews?.total,
                rating: reviews?.starsAverage,
                productReviews: productReviews?.productReviews,
                links: productReviews?.links,
            },
        };
    },

    head() {
        // eslint-disable-next-line prefer-const
        let { title, description } = this.brand.details ? this.brand.details : { title: '', description: '' };

        const preloads = [
            {
                rel: 'preload',
                as: 'image',
                href: `https://cdn.dundle.com/resources/images/flags/${
                    this.brand.selectedRegion ? this.brand.selectedRegion : this.brand.selectedCurrency
                }.svg`,
            },
            {
                rel: 'preload',
                as: 'image',
                href: 'https://cdn.dundle.com/resources/images/themes/logos/dundle.com-2023/dundle-logo-light-theme-default.svg',
            },
        ];
        if (this.productsInStock) {
            preloads.push({
                rel: 'preload',
                as: 'image',
                href:
                    'https://dundle.com/cdn-cgi/image/format=auto,width=110,fit=cover,quality=85/https://cdn.dundle.com/resources/' +
                    this.$product.getImage480({ brand: this.brand }),
            });
        }

        return {
            __dangerouslyDisableSanitizers: ['title', 'meta', 'script'],

            title: title
                ? this.translateParams(title, { cheapestValue: this.$brand.cheapestBrandValue(this.brand) })
                : this.$locale.domain,

            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: description
                        ? this.translateParams(description, {
                              cheapestValue: this.$brand.cheapestBrandValue(this.brand),
                          })
                        : '',
                },
                {
                    hid: 'robots',
                    name: 'robots',
                    content: this.shouldBeIndexed ? 'index, follow' : 'noindex, nofollow',
                },
            ],

            link: preloads,
        };
    },
    computed: {
        shouldBeIndexed() {
            if (this.brand.slug === 'buxongo') return false;

            return (
                this.$locale.isTarget() &&
                this.brand.availableInLanguages.includes(this.$locale.language) &&
                this.brand.availableInRegions.includes(this.$locale.region)
            );
        },
    },
    watchQuery: ['c'],
    mounted() {
        // push call to action button to gtm on the brand page if products are all out of stock
        if (this.products.every(item => item.stock === 0)) {
            const value = this.brand.slug + ' - ' + (this.brand.selectedRegion || this.brand.selectedCurrency);
            this.$gtm?.push({ event: 'event_out_of_stock_products', value });
        }

        this.$gtm?.push({ event: `page_view_brand`, value: this.brand.slug });
        this.$analytics?.viewItemList('brand_page', 'Brand Page', this.products, this.brand);
    },

    breadcrumb({ app }, data) {
        return [
            {
                name: app.$locale.regio.toLowerCase(),
                link: app.$locale.path('index'),
                url: 'https://' + app.$locale.domain + app.$locale.path('index'),
            },
            {
                name: app.$category.getName(data.brand.category),
                link: app.$category.getPath(data.brand.category),
                url: 'https://' + app.$locale.domain + app.$category.getPath(data.brand.category),
            },
            {
                name: app.$brand.getName(data.brand),
                url: 'https://' + app.$locale.domain + app.$brand.getPath(data.brand),
            },
        ];
    },

    httpCaching: {
        maxAge: 60 * 30,
    },

    pageCaching: {
        maxAge: 60 * 30,
    },
};
</script>
<style lang="scss" scoped>
#page-brand {
    .related-brands {
        .related-brand-wrapper {
            padding: 0 7.5px;

            & > div {
                padding: 7.5px;
            }

            .related-brand {
                padding: 1rem;
                border: 1px solid $color-grey-3;
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: $border-radius-default;

                .name {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    max-width: 100%;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
<i18n src="@dundle/locale/data/giftcard.json"></i18n>
